import { ref, computed, watch } from "@vue/composition-api";
// import { title } from '@core/utils/filter'
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { debounce } from "lodash";

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null);
  const tenantFilter = ref(null);
  const toast = useToast();

  const tableColumns = [
    { label: "density_device.actions", key: "actions" },
    { label: "density_device.name", key: "name", sortable: true },
    { label: "density_device.description", key: "description", sortable: true },
    { label: "density_device.email", key: "email", sortable: true },
    { label: "density_device.password", key: "password", sortable: true },
    {
      label: "date.created_at",
      key: "created_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
    {
      label: "date.updated_at",
      key: "updated_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  store.dispatch("tenant/getAll");

  const tenantOptions = computed(() => store.getters["tenant/getSelectedItems"]);

  const fetchList = (ctx, callback) => {
    store
      .dispatch("density/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_tenant: tenantFilter.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([ currentPage, tenantFilter, perPage ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tenantFilter,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    refetchData,
    tenantOptions,
  };
}
